import React from 'react'

import Layout from '../components/Layout'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import image_background from '../images/solutions/facturacion.png'
import FooterContact from './../components/FooterContact'
import PageContentFacturacion from '../components/Solutions/Pages/Facturacion'

const Facturacion = props => {
  return (
    <Layout
      title="Servicio de Facturacón Electrónica"
      description="Con nuestra Solución en Facturación Electrónica no es necesario instalar nuestro sistema en su ordenador, ya que el acceso es completamente por Internet ya sea a través de cualquier ordenar o dispositivo inteligente con acceso a internet."
      {...props}
    >
      <SectionHeaderImage image={image_background} position="90% 0" />
      <PageContentFacturacion />
      <FooterContact />
    </Layout>
  )
}

export default Facturacion
