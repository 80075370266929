import React from 'react'
import _get from 'lodash/get'
import _times from 'lodash/times'

import { graphql, useStaticQuery } from 'gatsby'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { ImageStyled, TitleSection } from '../../common/Index.styled'
import Section from '../../common/Section'
import { Img } from '../../SectionVideo/SectionImagen.styled'
import { Col, Row } from 'react-styled-flexboxgrid'
import { ContractButtonSmall } from '../SolutionDetail.styled'

const PageContentFacturacion = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "billing" } }) {
        edges {
          node {
            name
            childImageSharp {
              fixed(height: 375) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Section>
      <TitleSection>
        <FormattedMessage id="solutions.facturacion.title" />
      </TitleSection>
      <div>
        <p>
          <FormattedHTMLMessage id="solutions.facturacion.content.p1" />
        </p>
        <Img
          fixed={_get(
            edges.find(({ node }) => node.name === 'as_iviewer_logo'),
            'node.childImageSharp.fixed'
          )}
          style={{ height: 50, width: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
        />
        <p>
          <FormattedHTMLMessage id="solutions.facturacion.content.p2" />
        </p>
        <Img
          fixed={_get(
            edges.find(({ node }) => node.name === 'as_iviewer_platforms'),
            'node.childImageSharp.fixed'
          )}
          style={{ height: 200, width: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
        />
        <h2>
          <FormattedMessage id="solutions.facturacion.content.subtitle" />
        </h2>
        <ol>
          {_times(11, String).map((value, key) => (
            <li key={key}>
              <FormattedMessage
                id={`solutions.facturacion.content.list.item${value}`}
              />
            </li>
          ))}
        </ol>
        <p>
          <FormattedHTMLMessage id="solutions.facturacion.content.p2" />
        </p>
        <h3>
          <FormattedMessage id="solutions.facturacion.content.subtitle2" />
        </h3>
        <ul>
          {_times(3, String).map((value, key) => (
            <li key={key}>
              <FormattedMessage
                id={`solutions.facturacion.content.list2.item${value}`}
              />
            </li>
          ))}
        </ul>
        <Row center="xs">
          <Col xs={12} sm={6} style={{ margin: '2rem 0' }}>
            <Row between="xs" middle="xs">
              <ImageStyled
                fixed={_get(
                  edges.find(({ node }) => node.name === 'sicofi_platform'),
                  'node.childImageSharp.fixed'
                )}
                style={{ marginLeft: 'auto' }}
                imgStyle={{ objectFit: 'contain' }}
              />
              <div
                style={{
                  width: '10px',
                  borderRight: '3px solid #6D6E71',
                  height: '250px',
                }}
              />
            </Row>
          </Col>
          <Col xs={12} sm={6} style={{ margin: '2rem 0' }}>
            <Row center="xs">
              <Img
                fixed={_get(
                  edges.find(({ node }) => node.name === 'sicofi_logo'),
                  'node.childImageSharp.fixed'
                )}
                style={{ height: 120 }}
                imgStyle={{ objectFit: 'contain' }}
              />
              <Col
                xs={10}
                xsOffset={1}
                style={{ textAlign: 'center', marginLeft: 0 }}
              >
                <p>
                  <FormattedHTMLMessage id="solutions.facturacion.content.p4" />
                </p>
                <p>
                  <FormattedHTMLMessage id="solutions.facturacion.content.p5" />
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <ContractButtonSmall to="/contacto/" small>
          <FormattedMessage id="element.button_hire" />
        </ContractButtonSmall>
      </div>
    </Section>
  )
}

export default PageContentFacturacion
